<template lang="html">
  <div class="inner">
    <vue-loading v-if="loading"></vue-loading>
    <div v-if="loaded">
      <div class="blog__mv">
        <img id="blog__mv"
          :width="item.blog__mv.width"
          :height="item.blog__mv.height"
          :src="item.blog__mv.url"
        />
        <div class="category__block">
          <div class="category" id="category">{{ item.category }}</div>
          <div class="date" id="date">{{ new Date(item.date).toLocaleDateString() }}</div>
        </div>
        <h2 class="blog-title" id="title">{{ item.title }}</h2>
      </div>
      <div class="blog__main" v-html="item.blog__main"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AppBlogDetail',
  data() {
    return {
      loading: true,
      loaded: false,
      item: null,
      status: 0,
      previewStatus: 0,
    }
  },
  created() {
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id')
    const previewKey = urlParams.get('previewKey')
    const previewStatus = urlParams.get('previewStatus')
    const url = process.env.VUE_APP_CMS_API_BASE_URL
    const key = process.env.VUE_APP_CMS_API_KEY
    const params = {}
    if (previewKey !== null) {
      params.draftKey = previewKey
      if (previewStatus) {
        if (/^\d+$/.test(previewStatus)) {
          this.previewStatus = parseInt(previewStatus, 10)
        }
      }
    }
    axios
      .get(url + '/blog/' + id, {
        headers: {
          'X-API-KEY': key,
        },
        params,
      })
      .then((response) => {
        this.item = response.data
        this.loading = false
        this.loaded = true
      })
      .catch(() => {
        this.loading = false
      })
  },
}
</script>
