import Vue from 'vue'
import VueLoading from 'vue-loading-template'
import utils from './mixins/utils'
import AppBlogIndex from './components/AppBlogIndex.vue'
import AppBlogDetail from './components/AppBlogDetail.vue'
import AppBlogDetailIndex from './components/AppBlogDetailIndex.vue'

Vue.config.productionTip = false

Vue.use(VueLoading, {
  type: 'spiningDubbles',
  color: '#008731',
})

Vue.mixin(utils)

new Vue({
  el: '#app',
  components: {
    AppBlogIndex,
    AppBlogDetail,
    AppBlogDetailIndex,
  },
})
