<template>
  <div class="blog__flex" id="blog__flex">
    <vue-loading v-if="loading"></vue-loading>
    <div class="blog__block" v-for="(item, index) in list" :key="index">
      <img id="blog__mv"
        :width="item.blog__mv.width"
        :height="item.blog__mv.height"
        :src="item.blog__mv.url"
      />
      <div class="text__block">
        <h4>{{ item.title }}</h4>
      </div>
      <div class="category__block">
        <div class="category" id="category">{{ item.category }}</div>
        <div class="date" id="date">{{ new Date(item.date).toLocaleDateString() }}</div>
      </div>
      <a :href="'/blog/detail.html?id=' + item.id"></a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { addYears, isAfter } from 'date-fns'

export default {
  name: 'AppBlogDetailIndex',
  props: {
    title: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 10000, // 指定しない場合、microcmsのデフォルト値になるためデフォルトで大きな値を指定する
    },
    limitYears: {
      type: Number,
      default: 0, // 0=無制限
    },
  },
  data() {
    return {
      loading: true,
      list: [],
    }
  },
  created() {
    const url = process.env.VUE_APP_CMS_API_BASE_URL
    const key = process.env.VUE_APP_CMS_API_KEY
    const params = {
      orders: '-display_date,-updatedAt',
    }
    if (this.limit) {
      params.limit = this.limit
    }
    axios
      .get(url + '/blog', {
        headers: {
          'X-API-KEY': key,
        },
        params,
      })
      .then((response) => {
        const today = new Date()
        const dateLimit = this.limitYears
          ? addYears(today, this.limitYears * -999)
          : null
        this.list = (response.data.contents || []).filter((v) => {
          if (!dateLimit) return true
          const displayDate = v.display_date ? Date.parse(v.display_date) : null
          return displayDate && isAfter(displayDate, dateLimit)
        })
        this.loading = false
      })
  },
}
</script>
